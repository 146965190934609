<template>
  <div class="bank-question">
    <div>
      <div class="d-flex align-center justify-md-space-between">
        <div class="bank-question-title">
          <span
            class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text"
          >
            Bank Soal
          </span>
          <small class="text-subtitle-2">Kumpulan soal untuk latihan belajar</small>
        </div>
        <div class="bank-question-cta">
          <v-btn
            color="primary"
            x-large
            :to="{ name: 'bank-teacher-add'}"
          >
            Tambah Bank Soal
          </v-btn>
        </div>
      </div>
      <div>
        <v-row class="my-7">
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="search"
                outlined
                dense
                label="Cari Bank Soal"
                class="white"
                hide-details="none"
                @change="searchHandler($event)"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="8"
            lg="8"
            md="8"
            sm="12"
            cols="12"
            class="py-0"
          >
            <v-row>
              <v-col
                md="4"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <span class="d-block me-2 mb-2 my-md-2 my-lg-2 my-xl-2">Tingkat:</span>
                  <div>
                    <v-autocomplete
                      outlined
                      dense
                      label="Semua"
                      :items="levels"
                      class="white"
                      hide-details="none"
                      @change="filterByLevel($event)"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
              <v-col
                md="4"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <span class="d-block me-2 mb-2 my-md-2 my-lg-2 my-xl-2">Status:</span>
                  <div>
                    <v-autocomplete
                      outlined
                      dense
                      label="Semua"
                      :items="types"
                      class="white"
                      hide-details="none"
                      @change="filterByType($event)"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
              <v-col
                md="4"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <span class="d-block me-2 mb-2 my-md-2 my-lg-2 my-xl-2">Urutkan:</span>
                  <div>
                    <v-autocomplete
                      outlined
                      dense
                      label="Terbaru"
                      :items="sorts"
                      class="white"
                      hide-details="none"
                      @change="filterBySort($event)"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div>
        <div class="d-flex justify-between align-center mb-5 scroll-menu">
          <template>
            <div class="demo-space-x">
              <v-chip
                :color="categoryActive == '' ? 'primary' : ''"
                class="white"
                @click="filterByChip('')"
              >
                Semua
              </v-chip>
            </div>
          </template>
          <template>
            <div
              class="demo-space-x"
            >
              <v-chip
                v-for="bankCategory in bankCategories"
                :key="bankCategory.name"
                :color="categoryActive == bankCategory.uuid ? 'primary' : ''"
                class="white"
                @click="filterByChip(bankCategory.uuid)"
              >
                {{ bankCategory.name }}
              </v-chip>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!isLoadingCard">
        <v-row
          class="mb-4"
        >
          <v-col
            v-for="(bankQuestion,index) in bankQuestions"
            :key="index"
            sm="6"
            lg="4"
            md="4"
            cols="12"
          >
            <v-card
              height="100%"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="black"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :to="{ name: 'bank-teacher-edit', params : { uuid: bankQuestion.uuid } }">
                      <v-list-item-title>
                        <div class="d-flex">
                          <div>
                            <v-img
                              width="16"
                              src="@/assets/icons/edit.svg"
                            ></v-img>
                          </div>

                          <small class="ml-2">Edit</small>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                    <a @click.prevent="confirmDestroy(bankQuestion.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text class="pa-4">
                <router-link
                  class="text-decoration-none"
                  :to="{ name: 'bank-teacher-detail', params: { uuid: bankQuestion.uuid}}"
                >
                  <v-row class="align-center">
                    <v-col
                      md="4"
                    >
                      <div class="d-flex justify-center align-center">
                        <div>
                          <v-img
                            v-if="bankQuestion.bank_icon === null"
                            width="60"
                            class="mx-auto"
                            src="@/assets/icons/feature/art.svg"
                          ></v-img>
                          <v-img
                            v-else
                            width="60"
                            class="mx-auto"
                            :src="bankQuestion.bank_icon.icon"
                          ></v-img>
                        </div>
                      </div>
                    </v-col>
                    <v-col md="8">
                      <div class="align-content-space-between flex-wrap">
                        <span
                          class="d-inline-block text-truncate black--text font-weight-normal"
                          style="max-width: 150px"
                        >{{ bankQuestion.category.name }}</span>
                        <h3 class="mt-2 mb-3">
                          {{ bankQuestion.name }}
                        </h3>
                        <div class="d-md-flex justify-md-space-between">
                          <span class="d-inline-block mr-3 black--text font-weight-normal">{{ bankQuestion.question.length }} Soal</span>
                          <v-chip
                            text
                            color="success"
                            class="bank-chip v-chip-light-bg success--text text-capitalize"
                          >
                            {{ bankQuestion.type }}
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </router-link>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="mb-5"
        >
          <v-col
            v-for="i,index in 9"
            :key="index"
            md="4"
          >
            <v-skeleton-loader
              height="140"
              type="card-avatar"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>

      <div
        class="d-block text-center"
      >
        <div
          v-if="isEmpty"
          class="d-block"
        >
          <div class="d-flex justify-center">
            <div
              class="px-4 py-8 text-center"
            >
              <v-img
                src="@/assets/images/vector/empty.svg"
                class="mx-8 my-auto"
              ></v-img>
              <p class="mt-3 mb-0">
                Belum ada bank soal
              </p>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div
          class="mt-6"
        >
          <v-pagination
            v-if="totalPages"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            @change="paginationHandler"
          ></v-pagination>
        </div>
      </div>
    </div>
    <ConfirmDialog
      :visible="confirmDialog"
      :width="width"
      header="Hapus Bank Soal"
      :is-loading-button="isLoadingButton"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus bank soal “{{ bankQuestion.name }}” ?
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiNoteMultipleOutline, mdiNoteOutline } from '@mdi/js'

import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
// import { ref } from '@vue/composition-api'

export default {
  name: 'Bank',
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      icons: {
        mdiNoteMultipleOutline,
        mdiNoteOutline,
        mdiDotsVertical,
      },
      type: '',
      order: '',
      level: '',
      types: ['Semua', 'Umum', 'Sekolah', 'Kabupaten', 'Provinsi'],
      sorts: ['Semua', 'Terbaru', 'Terlama', 'Banyak Soal', 'Sedikit Soal', 'A-Z', 'Z-A'],
      levels: [],
      bankCategories: [],
      bankQuestions: [],
      bankQuestion: {},
      school: [],
      school_uuid: '',
      isLoadingButton: false,
      isLoadingCard: true,
      isLoadingChip: false,
      confirmDialog: false,
      isEmpty: false,
      width: 420,
      search: '',

      totalPages: 0,
      totalItems: 0,
      page: 1,
      category: '',
      role: '',
      categoryActive: '',
    }
  },
  watch: {
    search: {
      handler() {
        this.isLoadingCard = true
        this.getBankQuestion({ search: this.search })
      },
    },
    page: {
      handler() {
        this.isLoadingCard = true
      },
    },
    level: {
      handler() {
        this.isLoadingCard = true
        this.getBankQuestion({ level: this.level })
      },
    },
    type: {
      handler() {
        this.isLoadingCard = true
        this.getBankQuestion({ type: this.type })
      },
    },
    order: {
      handler() {
        this.isLoadingCard = true
        this.getBankQuestion({ order_by: this.order })
      },
    },
    category: {
      handler() {
        this.isLoadingCard = true
        this.getBankQuestion({ category_uuid: this.category })
      },
    },
  },
  async mounted() {
    this.getLevel()
    await this.getSchoolUuid()
    await this.getBankQuestion()
    this.getBankCategory()
    this.isLoadingCard = false
  },
  methods: {
    // getRole() {
    //   this.role = JSON.parse(localStorage.getItem('user')).user.role
    // },
    // checkRole() {
    //   if (this.role === 'teacher') {
    //     this.tabs = [
    //       { icon: mdiNoteMultipleOutline, title: 'Bank Soal' },
    //       { icon: mdiNoteOutline, title: 'Arsip Soal' },
    //     ]
    //   } else {
    //     this.tabs = [{ icon: mdiNoteMultipleOutline, title: 'Bank Soal' }]
    //   }
    // },
    // reRenderComponent(index) {
    //   switch (index) {
    //     case 0:
    //       this.componentKey += 1
    //       break
    //     case 1:
    //       this.componentKey2 += 1
    //       break
    //     default:
    //       break
    //   }
    // },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
        this.employee_uuid = el.uuid
      })

      return true
    },
    getLevel() {
      const levele = JSON.parse(localStorage.getItem('user')).user.details
      this.role = JSON.parse(localStorage.getItem('user')).user.role
      if (this.role !== 'admin') {
        levele.forEach(el => {
          if (el.school.jenjang === 'SD') {
            this.levels = ['Semua', 'Kelas 1 SD', 'Kelas 2 SD', 'Kelas 3 SD', 'Kelas 4 SD', 'Kelas 5 SD', 'Kelas 6 SD']
          } else if (el.school.jenjang === 'SMP') {
            this.levels = ['Semua', 'Kelas 1 SMP', 'Kelas 2 SMP', 'Kelas 3 SMP']
          } else if (el.school.jenjang === 'SMA') {
            this.levels = ['Semua', 'Kelas 1 SMA', 'Kelas 2 SMA', 'Kelas 3 SMA']
          } else if (el.school.jenjang === 'SMK') {
            this.levels = ['Semua', 'Kelas 1 SMK', 'Kelas 2 SMK', 'Kelas 3 SMK']
          }
        })
      } else {
        this.levels = [
          'Semua',
          'Kelas 1 SD',
          'Kelas 2 SD',
          'Kelas 3 SD',
          'Kelas 4 SD',
          'Kelas 5 SD',
          'Kelas 6 SD',
          'Kelas 1 SMP',
          'Kelas 2 SMP',
          'Kelas 3 SMP',
          'Kelas 1 SMA',
          'Kelas 2 SMA',
          'Kelas 3 SMA',
        ]
      }
    },
    searchHandler(data) {
      this.search = data
    },

    filterByChip(uuid) {
      this.category = uuid
      this.categoryActive = uuid
    },
    filterByLevel(data) {
      this.level = data

      if (data === null || data === 'Semua') {
        this.level = ''
      }
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.order = 'newest'
      } else if (data === 'Terlama') {
        this.order = 'oldest'
      } else if (data === 'Banyak Soal') {
        this.order = 'many'
      } else if (data === 'Sedikit Soal') {
        this.order = 'least'
      } else if (data === 'A-Z') {
        this.order = 'asc'
      } else if (data === 'Z-A') {
        this.order = 'desc'
      } else if (data === 'Semua') {
        this.order = ''
      }
    },
    filterByType(data) {
      if (data === 'Umum') {
        this.type = 'umum'
      } else if (data === 'Sekolah') {
        this.type = 'sekolah'
      } else if (data === 'Kabupaten') {
        this.type = 'kabupaten'
      } else if (data === 'Provinsi') {
        this.type = 'provinsi'
      } else if (data === 'Semua') {
        this.type = ''
      }
    },

    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    getBankCategory(params = {}) {
      this.$services.ApiServices.list('bankcategory', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.bankCategories = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    async getBankQuestion(params = {}) {
      this.isLoadingCard = true
      if (this.role !== 'admin') {
        await this.$services.ApiServices.list('questionbank', {
          ...params,
          employee_uuid: this.employee_uuid,
        }).then(
          ({ data }) => {
            this.isLoadingCard = true
            this.bankQuestions = data.data
            this.isEmpty = false
            if (this.bankQuestions.length < 1) {
              this.isEmpty = true
            }
            this.totalItems = data.meta.total
            this.totalPages = data.meta.last_page
            this.isLoadingCard = false
          },
          err => {
            console.error(err)
          },
        )
        this.isLoadingCard = false
      } else {
        await this.$services.ApiServices.list('questionbank', {
          ...params,
          per_page: '9',
        }).then(
          ({ data }) => {
            this.isLoadingCard = true
            this.bankQuestions = data.data
            this.totalItems = data.meta.total
            this.totalPages = data.meta.last_page
            this.isLoadingCard = false
          },
          err => {
            console.error(err)
          },
        )
        this.isLoadingCard = false
      }
    },
    confirmDestroy(uuid) {
      this.bankQuestion.uuid = uuid
      this.confirmDialog = true
      this.$services.ApiServices.get('questionbank', uuid).then(
        ({ data }) => {
          this.bankQuestion = data.data
        },
        err => console.error(err),
      )
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('questionbank', this.bankQuestion.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.getBankQuestion()
      this.confirmDialog = false
      this.isLoadingButton = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>
<style scoped>
/* .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  border-bottom: 1px solid rgba(94, 86, 105, 0.14) !important;
  box-shadow: none !important;
}
.v-tabs-items::v-deep,
.v-tabs::v-deep .v-tabs-bar {
  background: transparent !important;
} */
.scroll-menu {
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-menu:hover {
  overflow-x: auto;
}
.demo-space-x::v-deep .v-chip {
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 24px !important;
}

.v-chip.v-size--default::v-deep {
  height: 100% !important;
}

.btn-delete {
  color: #e30000 !important;
}
</style>
